import axios from "@axios";

export default {
  namespaced: true,
  state: {
    templateOptions: [],
    editorsOptions: [],
  },
  getters: {},
  mutations: {
    setLog(state, val) {
      state.log = val;
    },
    setTemplates(state, val) {
      state.templateOptions = val;
    },
    setEditors(state, val) {
      state.editorsOptions = val;
    },
  },
  actions: {
    fetchLog(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/templatescalllog", data)
          .then((response) => {
            ctx.commit("setLog", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchTemplates(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/templates", {
            params: {
              selectedFields: "name, id",
            },
          })
          .then((response) => {
            let templates = [];
            response.data.templates.results.map((template) => {
              templates.push({
                label: template ? template.name : "Deleted",
                value: template ? template.id : null,
              });
            });
            ctx.commit("setTemplates", templates);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchEditors(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/users", {params: {limit: 100, selectedFields: "id name"}})
          .then((response) => {
            let users = [];
            response.data.results.map((editor) => {
              users.push({ label: editor.name, value: editor.id });
            });
            ctx.commit("setEditors", users);

            resolve(response.data);
          })
          .catch((e) => reject(e));
      });
    },
  },
};
