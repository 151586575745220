<template>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted"
          >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
          {{ dataMeta.of }} entries</span
        >
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model.sync="propModel"
          :total-rows="totalData.length || totalData"
          :per-page="10"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BPagination } from "bootstrap-vue";
export default {
  components: { BRow, BCol, BPagination },
  props: ["dataMeta", "currentPage", "totalData"],
  computed: {
    propModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.$emit("pagination:change", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
