import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { historyTableColumns } from "../../../../../../../contilt/configs/tables/constants/tablesdata";
import { getBasicTableVars } from "../../../../../../../contilt/configs/tables/logic/tablesBasicLogic";

export default function useUsersList(data) {
  // Use toast
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (store.state["app-user"].templateOptions.length == 0) {
    store.dispatch("app-user/fetchTemplates");
  }
  if (
    store.state["app-user"].editorsOptions.length == 0 &&
    userData.role && userData.role.toLowerCase().indexOf("admin") > -1
  ) {
    store.dispatch("app-user/fetchEditors");
  }

  const toast = useToast();

  const tableColumns = historyTableColumns;

  const templateFilter = ref(data.template || null);
  const searchFilter = ref(data.search || null);
  const editorFilter = ref(data.editor || null);

  const {
    refUserListTable,
    perPage,
    totalUsers,
    currentPage,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refetchData,
  } = getBasicTableVars();

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  watch(
    [currentPage, perPage, templateFilter, editorFilter, searchFilter],
    () => {
      refetchData();
    }
  );

  const fetchUsers = (ctx, callback) => {
    let dataToSend = {
      limit: 10,
      page: currentPage.value,
    };
    if (searchFilter.value) {
      dataToSend.topic = searchFilter.value;
    }
    if (editorFilter.value) {
      dataToSend.user = editorFilter.value;
    }
    if (templateFilter.value) {
      dataToSend.template = templateFilter.value;
    }

    const filters = { params: dataToSend };

    store
      .dispatch("app-user/fetchLog", filters)
      .then((response) => {
        const { results, totalResults } = response.data;
        perPage.value = 10;

        callback(results);
        totalUsers.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  let templateOptions = store.state["app-user"].templateOptions;

  return {
    fetchUsers,
    tableColumns,
    templateOptions,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    templateFilter,
    editorFilter,
    searchFilter,
  };
}
