import { ref } from "@vue/composition-api";

export function getBasicTableVars() {
  const refUserListTable = ref(null);

  // Table Handlers
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "USER") return "success";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "ADMIN") return "primary";
    if (role === "SUPERADMIN") return "primary";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "USER") return "UserIcon";
    if (role === "SUPERADMIN") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "ADMIN") return "SettingsIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "PENDING") return "warning";
    if (status === "ACTIVE") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    refUserListTable,
    perPage,
    totalUsers,
    currentPage,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refetchData,

    //UI
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
  };
}
