<template>
  <div>
    <h4><strong>Generations history for editors </strong></h4>
    <hr />
    <history-table></history-table>
  </div>
</template>

<script>
import HistoryTable from "../dashboard/components/tables/historytable.vue";

export default {
  components: {
    HistoryTable,
  },
};
</script>

<style lang="scss" scoped></style>
