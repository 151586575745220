<template>
  <div>
    <b-row class="ecommerce-searchbar">
      <b-col lg="12">
        <history-filters
          :role-filter.sync="templateFilter"
          :status-filter.sync="editorFilter"
          :role-options="$store.state['app-user'].templateOptions"
          :status-options="$store.state['app-user'].editorsOptions"
          :search-filter.sync="searchFilter"
          :defaultValue="this.$router.history.current.query.search || ''"
          @update:searchFilter="
            (e) => updateRouteQuery({ key: 'search', value: e })
          "
          @update:roleFilter="
            (e) => updateRouteQuery({ key: 'template', value: e })
          "
          @update:statusFilter="
            (e) => updateRouteQuery({ key: 'editor', value: e })
          "
        />
      </b-col>
    </b-row>

    <b-card no-body class="card-company-table mt-1">
      <b-card-title> </b-card-title>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(template)="data">
          <b-media vertical-align="center">
            {{ data.item.template ? data.item.template.name : "Deleted" }}
          </b-media>
        </template>

        <template #cell(topic)="data">
          <b-media vertical-align="center">
            {{ data.item.topic }}
          </b-media>
        </template>

        <template #cell(editor)="data">
          <b-media vertical-align="center">
            {{ data.item.user.name }}
          </b-media>
        </template>

        <template #cell(date)="data">
          <b-media vertical-align="center">
            {{ getDate(data.item.createdAt) }}
          </b-media>
        </template>

        <template #cell(feedback)="data">
          <b-media vertical-align="center">
            <!-- {{ data.item.id }} -->
            No Feedback
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <b-link
              :to="{
                path: `/templateToRun/${
                  data.item.template ? data.item.template._id : ''
                }?sessionId=${data.item ? data.item.id : ''} `,
                params: {
                  id: data.item.template ? data.item.template._id : '',
                },
              }"
              :disabled="data.item.template ? false : true"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                :disabled="!data.item.template"
              >
                view
              </b-button>
            </b-link>
          </div>
        </template>
      </b-table>

      <pagination
        :dataMeta="dataMeta"
        :currentPage.sync="currentPage"
        :totalData="totalUsers"
        v-on:pagination:change="(value) => updatePagination(value)"
      >
      </pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BCardTitle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useUsersList from "./tables-logic/historylogic";
import store from "@/store";
import historyStoreModule from "../../../history/storeModule/historymodule";
import { onUnmounted } from "@vue/composition-api";
import HistoryFilters from "../../../history/components/historyfilters.vue";
import router from "@/router";
import Pagination from "../pagination/pagination.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BCardTitle,
    HistoryFilters,
    Pagination,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user"; //app-generation-history

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, historyStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);

    });



    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      templateFilter,
      editorFilter,
      searchFilter,
      templateOptions,
    } = useUsersList(router.history.current.query);

    return {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      templateOptions,
      templateFilter,
      editorFilter,
      searchFilter,
    };
  },
  methods: {
    updatePagination(value) {
      this.currentPage = value;
    },
    getDate(value) {
      const mydate = new Date(value);
      return mydate.toDateString();
    },
    updateRouteQuery(data) {
      this.$router.push({
        path: "/history/",
        query: {
          ...this.$router.history.current.query,
          [data.key]: data.value,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
