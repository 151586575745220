export const settingsUsersTableColumns = [
  { key: "user", sortable: false },
  { key: "email", sortable: false },
  { key: "role", sortable: false },
  { key: "quota", sortable: false },
  { key: "credits", sortable: false },
  { key: "status", sortable: false },
  { key: "actions" },
];

export const historyTableColumns = [
  { key: "template", sortable: false },
  { key: "topic", sortable: false },
  { key: "editor", sortable: false },
  { key: "date", sortable: false },
  { key: "feedback", sortable: false },
  { key: "actions" },
];
