<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col lg="4">
          <label>Topic</label>

          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              class="search-product"
              @input="debounceSearch"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="4" md="4">
          <label>Template</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>
        <b-col
          cols="4"
          md="4"
          v-if="$store.state.authStore.currentUser.role === 'SUPERADMIN' || $store.state.authStore.currentUser.role === 'ADMIN'"
        >
          <label>Editor</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,

    vSelect,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: [String, null],
      required: false,
    },
  },
  data() {
    return {
      searchTerm: this.defaultValue || "",
      debounce: "",
    };
  },
  methods: {
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$emit("update:searchFilter", this.searchTerm);
      }, 400);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
